import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({

    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light: {
                primary: '#3CC8FA',
                color1: '#97D7EC',
                color2: '#A5B9C0',
                dark: '#1D4351',
                background: '#ECF6F9',
                color5: '#CCE0E8'


            },
        },
    },
});
