<template>
    <v-sheet class="page-bg-doser  " tile>
        <div class="doser-container">
            <div class="upper-switcher">
                <v-btn-toggle v-model="appModeModel" dense mandatory borderless
                              rounded
                              color="primary">
                    <v-btn color="background" class="text-capitalize" value="simulation">
                        Измерение
                    </v-btn>
                    <v-btn color="background" class="text-capitalize" value="configuration">
                        Настройка
                    </v-btn>
                </v-btn-toggle>
            </div>
            <div class="slider-floating">
                <v-slide-x-transition>
                    <v-slider v-if="getAppMode === 'simulation' && !isTapperMode && !tapperDialog && !isTapperPrepareMode"
                              v-model="sliderModel"
                              min="10"
                              max="100"
                              :step="step"
                              vertical
                              track-color="background"
                              track-fill-color="primary"
                              thumb-label="always"
                              class="vertical-dpm-slider"
                    >
                        <template slot="append">
                            <v-btn small depressed fab color="background" @click="doserUp">
                                <v-icon color="primary">
                                    mdi-water-plus-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <template slot="prepend">
                            <v-btn depressed class="background" small fab icon @click="doserDown">
                                <v-icon color="primary">
                                    mdi-water-minus-outline
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-slider>
                </v-slide-x-transition>
            </div>
            <div
                    class="text-center canvas-container primary-border"
                    v-bind:class="{'dashed-border': tapperDialog || isTapperMode || isTapperPrepareMode,
                    'active-dashed-border': isTapperMode}"
                    @click="tap"
            >
                <span class="bottom-text">{{isTapperMode ? tapperText3 : ''}}</span>
                <div class="canvas-overlay">
                    <v-slide-x-reverse-transition>
                        <div v-if="isTapperPrepareMode || isTapperMode">
                            {{isTapperMode ? tapperText1 : 'Нажмите для начала'}}
                            <br/>
                            {{isTapperMode ? tapperText2 : ''}}
                            <br/>

                        </div>
                    </v-slide-x-reverse-transition>
                </div>
                <Dropper v-if="$route.path=='/meter'"
                       :bg-width="canvasWidth"
                       :bg-height="canvasHeight"
                       :bg-color="'transparent'"
                       ref="dropper"
                       v-on:tapper-update="tapperUpdate"
                       v-on:tapper-end="tapperEnd"
                />
            </div>

            <v-slide-x-transition>
                <div class="text-center" v-bind:class="[getAppMode === 'configuration' ? 'bottom-info-duo' : 'bottom-info-solo']"
                     v-if="!isTapperMode && !tapperDialog"
                >

                    <v-btn
                            outlined rounded color="primary"
                            background-color="background"
                            key="meter" v-if="getAppMode === 'configuration'" to="/settings"
                            block
                            class="font-weight-black parameters-button"
                    >
                        Параметры капельницы
                    </v-btn>

                    <v-text-field hide-details label="Капель/Мин" outlined rounded color="primary" readonly
                                  background-color="background"
                                  :value="getDropsPerMin"></v-text-field>
                    <v-text-field hide-details label="Мкг/Кг/Мин" outlined rounded color="primary" readonly
                                  background-color="background"
                                  :value="getInfusionSpeedFromReceipt"
                                   v-if="getAppMode === 'configuration'"
                    ></v-text-field>
                </div>
            </v-slide-x-transition>
        </div>
        <v-snackbar
                v-model="alertSnackbarModel"
                :timeout="3000"
                color="primary"
        >{{alertSnackbarText}}</v-snackbar>
        <v-dialog
            v-model="tapperDialog"
        >
            <v-card>
                <v-card-title>
                    Вы нажали на капельницу
                </v-card-title>
<!--                <v-divider/>-->
                <v-card-text>
                    Начать измерение скорости капельницы с помощью касаний?
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn
                            text
                            color="red"
                            @click="tapperDialog = false"
                    >
                        Нет
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                            text
                            color="green"
                            @click="tapperModeActivate"
                    >
                        Да
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
    import {mapGetters} from "vuex";
    import Dropper from "../components/Dropper";

    export default {
        name: "Main",
        components: {Dropper},
        data: () => ({
            tapperCount: undefined,
            tapperTime: undefined,
            alertSnackbarModel: false,
            alertSnackbarText: undefined,
            tapperDialog: false,
            canvasHeight: 240,
            canvasWidth: 340
        }),
        mounted() {
            this.canvasHeight = Math.round(document.getElementsByClassName('canvas-container')[0].clientHeight * 1)
            this.canvasWidth = Math.round(document.getElementsByClassName('canvas-container')[0].clientWidth * 0.7)
        },
        computed: {
            ...mapGetters(['getDropsPerMin', 'getInfusionSpeedFromReceipt', 'getDropSound', 'getAppMode', 'getSliderValue', 'getDropperMode']),
            step() {
                if (this.getSliderValue < 40) {
                    return 2
                } else {
                    return 10
                }
            },
            sliderModel: {
                get: function () {
                    return this.getSliderValue
                },
                set: function (value) {
                    this.$store.dispatch('updateSliderValue', value)
                }
            },
            appModeModel: {
                get: function () {
                    return this.getAppMode
                },
                set: function (value) {
                    this.$store.dispatch('updateAppMode', value)
                }

            },
            dropperMode: {
                get: function () {
                    return this.getDropperMode
                },
                set: function (value) {
                    this.$store.dispatch('updateDropperMode', value)
                }

            },
            isTapperMode: {
                get: function () {
                    return this.dropperMode === 'tapper_mode'
                },
                set: function (value) {
                    this.dropperMode = value ? 'tapper_mode' : 'drop_on'
                }
            },
            isTapperPrepareMode: {
                get: function () {
                    return this.dropperMode === 'tapper_prepare_mode'
                },
                set: function (value) {
                    this.dropperMode = value ? 'tapper_prepare_mode' : 'drop_on'
                }
            },
            tapperText1 () {
                let tapperTime = 'Времени осталось: 00:'
                let tapperTimeLimit = this.$refs.dropper.tapperTimeLimit
                if ((tapperTimeLimit - this.tapperTime) >= 10)
                    tapperTime += (tapperTimeLimit - this.tapperTime).toString()
                else
                    tapperTime += '0' + (tapperTimeLimit - this.tapperTime).toString()
                return tapperTime
            },
            tapperText2 () {
                let tapperCount = 'Нажатий: ' + this.tapperCount
                return tapperCount
            },
            tapperText3 () {
                let dpm = Math.round(this.tapperCount * 60 / this.tapperTime)
                if (dpm === Infinity)
                    return ''
                else
                    return 'Скорость: ' + dpm.toString() + ' кап. в минуту'
            }
        },
        methods: {
            tapperModeActivate () {
                this.isTapperPrepareMode = true
                this.tapperDialog = false
                // this.tap()
            },
            doserUp() {
                this.$store.dispatch('updateSliderValue', this.getSliderValue + this.step)
            },

            doserDown() {
                this.$store.dispatch('updateSliderValue', this.getSliderValue - this.step)
            },

            tap() {
                if (this.isTapperMode) this.$refs.dropper.tap()
                else if (this.isTapperPrepareMode) {
                    this.isTapperPrepareMode = false
                    this.isTapperMode = true
                    this.tap()
                }
                else if (this.appModeModel === 'simulation') this.tapperDialog = true
            },
            tapperUpdate([tapperCount, tapperTime]) {
                this.tapperCount = tapperCount
                this.tapperTime = tapperTime
            },
            tapperEnd() {
                this.isTapperMode = false
                this.alertSnackbarText = 'Скорость нажатий составила ' + Math.round(this.tapperCount * 60 / this.tapperTime) + ' кап. в минуту'
                this.alertSnackbarModel = true
            }
        },
        watch: {
            getAppMode (newMode) {
                if (newMode === 'configuration') {
                    this.dropperMode = 'drop_on'
                }
                if (this.isTapperMode) {
                    this.$refs.dropper.tapperEnd()
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
    .page-bg-doser {
        width: 100%;
        height: 100%;

        .slider-floating {
            bottom: 10vh;
            position: absolute;
            z-index: 1;
        }

        .doser-container {
            max-height: 99vh;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
            grid-template-rows: 1fr 2.5fr 1fr;

            .upper-switcher {
                display: flex;
                place-self: center;
                grid-row-start: 1;
                grid-row-end: 2;
                button {
                    font-size: 0.8rem;
                }
            }

            .bottom-info-duo {
                place-self: center;
                grid-row-start: 3;
                grid-row-end: 4;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 2rem;
                grid-row-gap: 1rem;
                .parameters-button {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }

            .bottom-info-solo {
                place-self: center;
                max-width: 50%;
            }
        }
    }

</style>