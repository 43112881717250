import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        active_substance_percentage: 6,
        active_substance_ml_per_vial: 15,
        infusion_speed_from_receipt: 200,
        solvent_volume_ml: 200,
        patient_weight: 90,
        drop_sound: false,
        mode: 'simulation',
        slider: 10,
        dropper_mode: 'drop_on',
        isDocumentHidden: false
    },
    mutations: {
        UPDATE_APP_MODE: (state, value) => {
            state.mode = value
        },
        UPDATE_SLIDER_VALUE: (state, value) => {
            state.slider = value
        },
        UPDATE_ACTIVE_SUBSTANCE_PERCENTAGE: (state, value) => {
            state.active_substance_percentage = value
        },
        UPDATE_ACTIVE_SUBSTANCE_ML_PER_VIAL: (state, value) => {
            state.active_substance_ml_per_vial = value
        },
        UPDATE_INFUSION_SPEED_FROM_RECEIPT: (state, value) => {
            state.infusion_speed_from_receipt = value
        },
        UPDATE_SOLVENT_VOLUME_ML: (state, value) => {
            state.solvent_volume_ml = value
        },
        UPDATE_PATIENT_WEIGHT: (state, value) => {
            state.patient_weight = value
        },
        SWITCH_DROP_SOUND: (state) => {
            state.drop_sound = !state.drop_sound
        },
        OFF_DROP_SOUND: (state) => {
            state.drop_sound = false
        },
        UPDATE_DROPPER_MODE: (state, value) => {
            state.dropper_mode = value
        },
        UPDATE_DOCUMENT_VISIBILITY: (state, value) => {
            state.isDocumentHidden = value
        }

    },
    actions: {
        updateAppMode: ({commit}, value) => commit('UPDATE_APP_MODE', value),
        updateActiveSubstancePercentage: ({commit}, value) => commit('UPDATE_ACTIVE_SUBSTANCE_PERCENTAGE', value),
        updateActiveSubstanceMlPerVial: ({commit}, value) => commit('UPDATE_ACTIVE_SUBSTANCE_ML_PER_VIAL', value),
        updateInfusionSpeedFromReceipt: ({commit}, value) => commit('UPDATE_INFUSION_SPEED_FROM_RECEIPT', value),
        updateSolventVolumeMl: ({commit}, value) => commit('UPDATE_SOLVENT_VOLUME_ML', value),
        updatePatientWeight: ({commit}, value) => commit('UPDATE_PATIENT_WEIGHT', value),
        switchDropSound: ({commit}) => commit('SWITCH_DROP_SOUND'),
        offDropSound: ({commit}) => commit('OFF_DROP_SOUND'),
        updateSliderValue: ({commit}, value) => commit('UPDATE_SLIDER_VALUE', value),
        updateDropperMode: ({commit}, value) => commit('UPDATE_DROPPER_MODE', value),
        updateDocumentVisibility: ({commit}, value) => commit('UPDATE_DOCUMENT_VISIBILITY', value),
    },
    getters: {
        getSliderValue: (state) => state.slider,
        getAppMode: (state) => state.mode,
        getActiveSubstancePercentage: (state) => state.active_substance_percentage,
        getActiveSubstanceMlPerVial: (state) => state.active_substance_ml_per_vial,
        getInfusionSpeedFromReceipt: (state) => state.infusion_speed_from_receipt,
        getSolventVolumeMl: (state) => state.solvent_volume_ml,
        getPatientWeight: (state) => state.patient_weight,
        getInfusionSpeedBasedOnWeight: (state) => state.patient_weight * state.infusion_speed_from_receipt,
        getDropsPerMin: (state, getters) => {
            if (state.mode === 'configuration') {
                let active_substance_mg = state.active_substance_percentage * 10
                let active_substance_mg_per_vial = active_substance_mg * state.active_substance_ml_per_vial
                let vial_amount = 1
                let total_substance_mg = vial_amount * active_substance_mg_per_vial
                let total_substance_mcg = total_substance_mg * 1000
                let solvent_and_substance_value_ml = state.solvent_volume_ml + state.active_substance_ml_per_vial * vial_amount
                let substance_per_ml_in_mcg = total_substance_mcg / solvent_and_substance_value_ml
                let ml_per_min = getters.getInfusionSpeedBasedOnWeight / substance_per_ml_in_mcg
                let drops_per_min = ml_per_min * 20
                if (isNaN(drops_per_min) || !isFinite(drops_per_min)) {
                    return 0
                }
                return Math.round(drops_per_min)
            } else {
                return state.slider
            }

        },
        getDropSound: (state) => state.drop_sound,
        getDropperMode: (state) => state.dropper_mode,
        getDocumentVisibility: (state) => state.isDocumentHidden,
    }

})

