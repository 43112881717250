<template>
    <v-app>
        <v-app-bar color="white" flat app>
            <v-slide-y-transition tag="div" mode="out-in">
                <div class="header-content" v-if="$route.name">
                    <div>
                        <v-fab-transition>
                            <v-btn  key="meter" v-if="$route.name ==='Капельница'" to="/settings" fab icon
                                    :disabled="true"
                                    v-bind:class="'opacity-0'"
                            >
                                <v-icon >
                                    mdi-cog-outline
                                </v-icon>
                            </v-btn>
                            <v-btn  key="back" v-if="$route.name ==='Параметры'" to="/meter" fab icon>
                                <v-icon>
                                    mdi-chevron-left
                                </v-icon>
                            </v-btn>
                        </v-fab-transition>

                    </div>
                    <div class="text-center header-text">
                        <span class="primary--text podkova-font text-center"
                              v-bind:class="{'opacity-0': homeStep < 2 && $route.path === '/'}"
                        >{{$route.name}}</span>
                    </div>
                    <div>
                        <v-fab-transition>
                            <v-btn @click="toggleSound" fab icon :key="getDropSound"
                                   :disabled="$route.name ==='Настройка'"
                                   v-bind:class="{'opacity-0' : $route.path ==='/settings' || $route.path ==='/'}">
                                <v-icon >
                                    {{getDropSound ? 'mdi-volume-high' : 'mdi-volume-off'}}
                                </v-icon>
                            </v-btn>
                        </v-fab-transition>
                    </div>
                </div>
            </v-slide-y-transition>
        </v-app-bar>
        <main>
            <v-container fluid class="fill-height ">
                <v-slide-x-transition mode="out-in">
                    <router-view/>
                </v-slide-x-transition>
            </v-container>
        </main>
    </v-app>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'App',

        data: () => ({
            homeStep: 0,
        }),
        computed: {
            ...mapGetters(['getDropSound', 'getAppMode', 'getDropsPerMin']),
            isHomeEnded () {
                return window.localStorage.homeEnded
            }
        },
        methods: {
            toggleSound() {
                this.$store.dispatch('switchDropSound')
            }
        },
        created() {
            const locOrientation = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation || screen.orientation.lock
            locOrientation('landscape')
        }
    };
</script>
<style lang="sass">
    @import "scss/main.scss"
</style>
