import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DropMeter from "../views/DropMeter";
import Settings from "../views/Settings";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Капельница',
        component: Home
    },
    {
        path: '/meter',
        name: 'Капельница',
        component: DropMeter
    }, {
        path: '/settings',
        name: 'Параметры',
        component: Settings
    },

]

const router = new VueRouter({
    routes, mode: 'history'
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})


router.beforeEach((to, from, next) => {
    if (from.path === '/settings' && to.path === '/meter') {
        next()
        return ''
    }
    const IsItABackButton = window.popStateDetected
    window.popStateDetected = false
    if (IsItABackButton) {
        next(false)
        return ''
    }
    next()
})

export default router
