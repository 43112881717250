<template>
    <v-sheet class="page-bg-settings pt-1" tile>
        <div class="settings-container">
            <v-container>
                <div class="active-substance-container">
                    <div class="active-substance-label">
                        Концентрация лекарственного вещества в ампуле
                    </div>

                    <v-text-field class="double-field"
                                  v-model="activeSubstancePercentageModel"
                                  background-color="background"
                                  rounded outlined color="primary"
                                  type="number"
                                  required
                                  hide-details
                                  @keydown="keyDown"
                    >

                        <template slot="append">
                            <v-btn outlined small :ripple="false" color="primary"
                                   class="ma-0 pa-0 text-capitalize append-label"
                                   rounded depressed>
                                <v-icon small>mdi-percent</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                    <v-text-field class="double-field"
                                  :value="activeSubstancePercentageModel*10"
                                  @input="updateActiveSubstance"
                                  background-color="background"
                                  rounded outlined color="primary"
                                  type="number"
                                  required
                                  hide-details
                                  @keydown="keyDown"
                    >
                        <template slot="append">
                            <v-btn outlined small :ripple="false" color="primary"
                                   class="ma-0 pa-0 text-capitalize append-label"
                                   rounded depressed>
                                <span>Мг</span>
                            </v-btn>
                        </template>
                    </v-text-field>
                </div>
                <div class="active-substance-label">
                    Суммарный объем лекарственного вещества до разведения
                </div>
                <v-text-field
                        v-model="activeSubstanceMlPerVialModel"
                        rounded outlined color="primary"
                        background-color="background"
                        type="number"
                        required
                        hide-details
                        @keydown="keyDown"
                >
                    <template slot="append">
                        <v-btn outlined small :ripple="false" color="primary"
                               class="ma-0 pa-0 text-capitalize append-label"
                               rounded depressed>
                            <span>Мл</span>
                        </v-btn>
                    </template>
                </v-text-field>

                <div class="active-substance-label text-center">
                    Требуемая скорость введения
                </div>

                <v-text-field
                        v-model="infusionSpeedFromReceiptModel"
                        rounded outlined color="primary"
                        background-color="background"
                        required
                        type="number"
                        hide-details
                        @keydown="keyDown"
                >
                    <template slot="append">
                        <v-btn small outlined :ripple="false" color="primary"
                               class="ma-0  text-capitalize append-label" rounded
                               depressed>
                            <span>Мкг/Кг/Мин</span>
                        </v-btn>
                    </template>
                </v-text-field>
                <div class="text-center">
                    <div class="active-substance-label">
                        Объем растворителя
                    </div>
                    <v-btn-toggle mandatory borderless
                                  rounded
                                  v-model="solventVolumeMlModel"
                                  color="primary">
                        <v-btn color="background" class="text-capitalize" large :value="100">
                            100<br/>
                            Мл
                        </v-btn>
                        <v-btn color="background" class="text-capitalize" large :value="200">
                            200<br/>
                            Мл
                        </v-btn>
                        <v-btn color="background" class="text-capitalize" large :value="400">
                            400<br/>
                            Мл
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <div class="text-center mt-2">
                    <div class="active-substance-label">Вес пациента, Кг</div>
                    <v-slider class="mt-6"
                              min="10"
                              v-model="patientWeightlModel"
                              max="200"
                              :step="10"

                              thumb-size="25"
                              track-color="background"
                              track-fill-color="primary"
                              thumb-label="always"
                    >
                    </v-slider>
                </div>

                <v-text-field hide-details label="Скорость инфузии" outlined rounded color="primary" readonly
                              background-color="background"
                              type="number"
                              :value="getDropsPerMin"
                >
                    <template slot="append">
                        <v-btn small outlined :ripple="false" color="primary"
                               class="ma-0  text-capitalize append-label" rounded
                               depressed>
                            <span>Капель/Мин</span>
                        </v-btn>
                    </template>
                </v-text-field>

                <v-btn  outlined rounded color="primary"
                        background-color="background"
                        @click="saveSettings"
                        block
                        large
                        class="mt-4 font-weight-black"
                        :disabled="isNaN(getDropsPerMin) || !isFinite(getDropsPerMin) || !getDropsPerMin"
                >
                    {{(isNaN(getDropsPerMin) || !isFinite(getDropsPerMin) || !getDropsPerMin) ? 'Некорректный ввод' : 'Установить'}}
                </v-btn>
            </v-container>
        </div>
    </v-sheet>
</template>

<script>
    import {mapGetters} from "vuex";
    import router from '@/router'

    export default {
        name: "Settings",
        data: () => ({
            valid: false,
            firstname: '',
            lastname: '',

            email: '',

        }),
        computed: {
            ...mapGetters(['getDropsPerMin', 'getActiveSubstancePercentage', 'getInfusionSpeedFromReceipt', 'getActiveSubstanceMlPerVial', 'getActiveSubstancePercentage',
                'getSolventVolumeMl', 'getPatientWeight']),
            infusionSpeedFromReceiptModel: {
                get: function () {
                    return this.getInfusionSpeedFromReceipt
                },
                set: function (value) {
                    this.$store.dispatch('updateInfusionSpeedFromReceipt', value)
                }

            },
            activeSubstanceMlPerVialModel: {
                get: function () {
                    return this.getActiveSubstanceMlPerVial
                },
                set: function (value) {
                    this.$store.dispatch('updateActiveSubstanceMlPerVial', value)
                }

            },
            activeSubstancePercentageModel: {
                get: function () {
                    return this.getActiveSubstancePercentage
                },
                set: function (value) {
                    this.$store.dispatch('updateActiveSubstancePercentage', value)
                }

            },

            solventVolumeMlModel: {
                get: function () {
                    return this.getSolventVolumeMl
                },
                set: function (value) {
                    this.$store.dispatch('updateSolventVolumeMl', value)
                }

            },
            patientWeightlModel: {
                get: function () {
                    return this.getPatientWeight
                },
                set: function (value) {
                    this.$store.dispatch('updatePatientWeight', value)
                }

            },

        },
        methods: {
            hideKeyboard() {
                setTimeout(function () {

                    var field = document.createElement('input')
                    field.setAttribute('type', 'text')
                    field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only left:0px;')
                    document.body.appendChild(field)

                    field.onfocus = function () {
                        setTimeout(function () {

                            field.setAttribute('style', 'display:none')
                            setTimeout(function () {
                                document.body.removeChild(field)
                                document.body.focus()
                            }, 14)

                        }, 200)
                    }
                    field.focus()

                }, 50)
            },
            keyDown (key) {
                this.firstname = key.key
                if (key.key === 'Enter') {
                    this.hideKeyboard()
                }
            },
            updateActiveSubstance(val) {
                this.$store.dispatch('updateActiveSubstancePercentage', val / 10)
            },
            saveSettings() {
                router.push('meter')
                this.$store.dispatch('updateAppMode', 'configuration')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-bg-settings {
        width: 320px;
        margin: auto;
        height: 100%;

    }
</style>