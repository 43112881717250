<template>
    <v-sheet class="page-bg" tile>
        <div class="vertical-align-middle px-8" v-if="step === 0">
            <div class="logo-container">
                <v-img alt="welcome-logo" src="/images/welcome-logo.svg"/>
                <div class="podkova-font logo-text primary--text ">
                    Капельница
                </div>
            </div>
            <div class="rubik-font description-block font-weight-black text-center" style="font-size: .8rem">
                Приложение для измерения скорости инфузии
                <div class="start-btn">
                    <v-btn large rounded block outlined @click="firstStart"> Начать</v-btn>
                </div>
            </div>
        </div>
        <div class="vertical-align-middle px-8" v-else-if="step === 1">
            <div class="disclaimer-block">
                <p class="dark--text rubik-font text-justify">
                    Нажатие кнопки "Согласен" означает, что Вы ознакомились с полным описанием приложения в Google Play,
                    <a href="/drop-meter-privacy-policy.html">политикой конфиденциальности</a>,
                    принимаете на себя всю ответственность по использованию этого
                    приложения и согласны со следующими утверждениями:
                </p>
                <ul>
                    <li>
                        Данное приложение может использоваться только в качестве справочника и не является инструментом
                        для коррекции параметров в оказания помощи, так как неверное введение исходных данных может
                        повлиять на скорость введения лекарства и ухудшить состояние пациента.
                    </li>
                    <li>
                        Вся ответственность за состояние пациента целиком лежит на пользователе, который осознает
                        механизм действия используемых в лечении лекарственных средств и отвечает за все последствия,
                        связанные с действиями пользователя.
                    </li>
                </ul>
                <div class="start-btn">
                    <v-btn large rounded block outlined @click="step++"> Начать</v-btn>
                </div>
            </div>
        </div>
        <v-carousel
                class="home-carousel"
                delimiter-icon=""
                :hide-delimiter-background="true"
                :hide-delimiters="true"
                v-else-if="step === 2"
                height="100%"
                :show-arrows="false"
                :continuous="false"
                v-model="carouselSlideNumber"
        >
            <v-carousel-item v-for="[index, slide] in slides.entries()" :key="index">
                <v-sheet
                        height="calc(100vh - 136px)"
                        tile
                        align="center"
                        justify="center"
                        style="display: grid"

                >
                        <img :src="slide.img" style="justify-self: center; align-self: center">
                        <div class="home-text px-4" style="justify-self: center; align-self: start">
                            <span v-html="slide.text"></span>
                            <div class="start-btn" v-if="index === 5">
                                <v-btn large rounded block outlined @click="endStart">Старт</v-btn>
                            </div>
                        </div>
                </v-sheet>
            </v-carousel-item>
            <v-row class="my-3 mx-5" justify="center">
                <v-col
                        cols="2"
                        v-for="index in slides.length"
                        :key="index"
                        align="center"
                >
                    <v-btn
                            v-bind:class="{'background-primary': carouselSlideNumber + 1 === index}"
                            fab
                            plain
                            light
                            outlined
                            color="primary"
                            max-width="15px"
                            max-height="15px"
                            @click="controlClick(index-1)"
                    />
                </v-col>
            </v-row>
        </v-carousel>
    </v-sheet>
</template>

<script>
    import router from "@/router";

    export default {
        name: "Home",
        data: () => ({
            step: 0,
            carouselSlideNumber: 0,
            slides: [
                {
                    img: 'images/home-slider/1.png',
                    text: 'В приложении есть <span class="primary--text">два режима</span>:<br/>' +
                        '1. <span class="primary--text">режим измерения</span> темпа инфузии своей капельницы<br/>' +
                        '2. <span class="primary--text">режим настройки</span> скорости инфузии путём введения точных параметров'
                },
                {
                    img: 'images/home-slider/2.png',
                    text: '<span class="primary--text">Режим измерения</span><br/>' +
                        'Используйте слайдер, чтобы ускорить или замедлить скорость инфузии для синхронизации с вашей капельницей'
                },
                {
                    img: 'images/home-slider/3.png',
                    text: '<span class="primary--text">Режим измерения</span><br/>' +
                        ' Начните нажимать на центр экрана в обозначенной области чтобы синхронизировать скорость инфузии с вашей капельницей'
                },
                {
                    img: 'images/home-slider/4.png',
                    text: '<span class="primary--text">Режим настройки</span><br/>' +
                        'Нажмите на кнопку “Параметры Капельницы”, чтобы настроить скорость введения препарата '
                },
                {
                    img: 'images/home-slider/5.png',
                    text: '<span class="primary--text">Режим настройки</span><br/>' +
                        'Введите свои параменты и нажмите кнопку “Установить”'
                },
                {
                    img: 'images/home-slider/6.png',
                    text: 'Нажмите <span class="primary--text">Старт</span>, чтобы ' +
                        'перейти к измерению скорости инфузии  '
                }
            ]
        }),
        methods: {
            firstStart() {
                if (this.isHomeEnded) {
                    router.push('/meter')
                }
                else {
                    this.step++
                }
            },
            endStart () {
                window.localStorage.homeEnded = true
                router.push('/meter')
            },
            controlClick (controlIndex) {
                if (controlIndex === this.carouselSlideNumber - 1 || controlIndex === this.carouselSlideNumber + 1) {
                    this.carouselSlideNumber = controlIndex
                }
            }
        },
        computed: {
            isHomeEnded () {
                return window.localStorage.homeEnded
            }
        },
        watch: {
            step(val) {
                this.$parent.$parent.$data.homeStep = val
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-bg {
        display: table;
        height: 100%;
        width: 100%;

        .display-table {
            display: table;
            height: 100%;
            width: 100%;
        }

        .vertical-align-middle {
            display: table-cell;
            vertical-align: middle;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            .logo-text {
                font-size: 2.5rem;
            }
        }

        .description-block {
            font-size: .8rem;

            .start-btn {
                margin-top: 100px;
                .v-btn {
                    /*box-shadow: 0px 10px 15px rgba(90, 194, 232, 0.3) !important;*/
                    color: var(--v-primary-base);
                    text-transform: none !important;

                }
            }
        }

        .disclaimer-block {
            font-size: 11px;

            .start-btn {
                margin-top: 20px;
                .v-btn {
                    /*box-shadow: 0px 10px 15px rgba(90, 194, 232, 0.3) !important;*/
                    color: var(--v-primary-base);
                    text-transform: none !important;

                }
            }
        }

        .home-carousel {
            height: calc(100vh - 64px);
            .v-sheet {
                grid-template-rows: 1fr auto;
            }

            img {
                width: 90%;
            }
            .home-text {
                padding-top: 10px;
                font-size: 13px;
            }
            .start-btn {
                margin-top: 10px;
                .v-btn {
                    /*box-shadow: 0px 10px 15px rgba(90, 194, 232, 0.3) !important;*/
                    color: var(--v-primary-base);
                    text-transform: none !important;

                }
            }
        }

        .background-primary {
            background-color: var(--v-primary-base);
        }


    }
</style>