<template>
    <div>
        <img id="hiddenImageDrop" style="display: none"/>
        <img id="hiddenImageBg" style="display: none"/>
        <img id="hiddenImageSplash" style="display: none"/>
        <img id="hiddenImageFlood1" style="display: none"/>
        <img id="hiddenImageFlood2" style="display: none"/>
        <canvas
                id="myCanvas"
                v-bind:width="bgWidth"
                v-bind:height="bgHeight"
        ></canvas>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    
    export default {
        name: 'Dropper',
        props: ['bgWidth', 'bgHeight', 'bgColor'],
        data: () => ({
            dropTopY: 176, // положение капли сверху
            dropBottomY: 696, // положение капли внизу
            splashY: 715, // положение всплеска
            splashAdvance: 11, // длительность всплеска (11 * renderTimeout)
            scaleK: 1, // коэф. изменения размеров (при изменении размеров канваса, все элементы сохраняют свои пропорции и положение)
            defaultDropTopY: 176, //     |
            defaultDropBottomY: 696, //  | => дефолтные размеры, для сохранения изначальных пропорций
            defaultSplashY: 715, //      |
            defaultSplashAdvance: 11, // |

            // капля
            dropImageUrl: '/images/drop.png',
            dropImage: null,
            defaultDropSize: [],
            
            // фон капельницы
            bgImageUrl: '/images/bg.png',
            bgImage: null,
            defaultBgSize: [],
            
            // всплеск
            splashImageUrl: '/images/splash.png',
            splashImage: null,
            defaultSplashSize: [],
            
            // поток (1 спрайт)
            flood1ImageUrl: '/images/flood1.png',
            flood1Image: null,
            defaultFlood1Size: [],
            
            // поток (2 спрайт)
            flood2ImageUrl: '/images/flood2.png',
            flood2Image: null,
            defaultFlood2Size: [],
            
            floodNum: 1, // номер потока (для чередования)
            floodFramesCounter: 0, // счетчик показанных кадров потока одного положения
            floodMaxFrames: 30, // кол-во кадров, которые показываается один спрайт потока
            floodDpm: 90, // getDropsPerMin, свыше которых активируется поток

            isSplash: false, // активен ли сейчас всплеск
            splashFramesMax: 12, // сколько кадров показывается всплеск
            splashFramesCount: 0, // счетчик показанных кадров всплеска
            
            canvas: null, 
            pps: 700, // скорость капли (пикселей в секунду)
            renderTimeout: 10, // интервал между сменой каадров (в мс) 
            indentsList: [], // список с очередью капель (записаны их верхние отсупы
            prevDropTime: 0, // запись в timestamp времени, когда упала предыдущая капля
            loopNumber: 0, // номер вызова рекурсивной функции loop()
            
            // звуки
            dropSound: new Audio('/sounds/drop_sound.mp3'),
            floodSound: new Audio('/sounds/flood_sound.mp3'),

            tapperMode: false, // активен ли режим тапа
            tapperCounter: 0, // счетчик тапов
            tapperTimeExpired: 0, // времени для тапов истекло
            tapperTimeLimit: 15, // время, которое дается на тапы (в секундах)
            tapperTimeout: undefined,

            started: false,
            loadedImgsCounter: 0,
            dropOn: false,
        }),
        methods: {
            // ОТРИСОВКА КАПЛИ. аргументы: topIndent - отступ сверху; sizeK - коэф. размера капли
            drawDrop (topIndent, sizeK=1) {
                let leftIndent = this.bgWidth / 2 - this.dropImage.width * sizeK / 2
                this.canvas.drawImage(this.dropImage, leftIndent, topIndent, this.dropImage.width * sizeK, this.dropImage.height * sizeK)
            },

            // ОТРИСОВКА ВСПЛЕСКА
            drawSplash () {
                let topIndent = this.splashY
                let leftIndent = this.bgWidth / 2 - this.splashImage.width / 2 - 5
                this.canvas.drawImage(this.splashImage, leftIndent, topIndent, this.splashImage.width, this.splashImage.height)
            },

            // ОТРИСОВКА ПОТОКА
            drawFlood () {
                this.floodSoundPlay()
                let topIndent = this.dropTopY
                let leftIndent = (this.bgWidth - this.flood1Image.width) / 2
                let localK = 0.97 // изменение размеров исходника (тк не были соблюдены пропорции)

                // отрисовка кадров в зависимости от их номера
                if (this.floodNum == 1) {
                    this.canvas.drawImage(this.flood1Image, leftIndent, topIndent, this.flood1Image.width * localK, this.flood1Image.height * localK)
                } else {
                    this.canvas.drawImage(this.flood2Image, leftIndent, topIndent, this.flood2Image.width * localK, this.flood2Image.height * localK)
                }

                // смена кадров потока
                if (this.floodFramesCounter >= this.floodMaxFrames) {
                    this.floodFramesCounter = 0
                    this.floodNum = this.floodNum == 1 ? 2 : 1
                }
                else {
                    this.floodFramesCounter++
                }
            },

            // ОЧИЩАЕТ ХОЛСТ И ОТРИСОВЫВАЕТ ЦВЕТНОЙ ФОН И КАПЕЛЬНИЦУ
            clearBg () {
                if (this.bgImage) {
                    this.canvas.clearRect(0, 0, this.bgWidth, this.bgHeight)
                    this.canvas.fillStyle = this.bgColor
                    this.canvas.fillRect(0, 0, this.bgWidth, this.bgHeight)
                    this.canvas.drawImage(this.bgImage, Math.round((this.bgWidth - this.bgImage.width) / 2), 0, this.bgImage.width, this.bgImage.height)
                }
            },

            // ОТРИСОВКА КАДРА
            drawFrame () {
                this.clearBg()
                if (this.dropInterval < 100 || !this.dropOn) {
                    return
                }

                // поток воды
                if (this.getDropsPerMin > this.floodDpm) {
                    this.drawFlood()
                    return;
                }

                // промежуток между падениями каплей
                if (Date.now() - this.prevDropTime >= this.dropInterval) {
                    this.indentsList.push(this.dropTopY)
                    this.prevDropTime = Date.now()
                }
                else {
                    this.drawDrop(this.dropTopY, (Date.now() - this.prevDropTime) / this.dropInterval)
                }

                // нужно ли отрисовывать всплеск
                if (this.isSplash) {
                    this.drawSplash()
                    this.splashFramesCount += 1
                    if (this.splashFramesCount == this.splashFramesMax) {
                        this.splashFramesCount = 0
                        this.isSplash = false
                    }
                }

                // обработка всех каплей из очереди indentsList
                for (let [i, indent] of this.indentsList.entries()) {
                    // проигрывается аудио, если оно включено
                    if (indent + this.dropStep * this.splashAdvance > this.dropBottomY &&
                    indent + this.dropStep * this.splashAdvance - this.dropStep < this.dropBottomY) {
                        this.dropSoundPlay()
                    }

                    // если капля достигает нижней границы, то она удаляется из очереди и активируется всплеск
                    if (indent > this.dropBottomY) {
                        this.indentsList.splice(i, 1)
                        this.isSplash = true
                        break
                    }

                    // увеличиваем отступ каждой капли сверху и отрисовываем ее
                    this.indentsList[i] += this.dropStep
                    this.drawDrop(indent)
                }
            },

            // РЕКУРСИВНАЯ ФУНКЦИЯ ДЛЯ ПОСЛЕДОВАТЕЛЬНОЙ ОТРИСОВКИ КАДРОВ
            loop (num) {
                // включен ли, проверка на номер loop (во избежение одновременного выполнения нескольких рекурсий)
                if (!this.dropOn || this.loopNumber != num || !this.isDropOnMode) {
                    return
                }
                setTimeout(() => {
                    if (!this.getDocumentVisibility)
                        this.drawFrame()
                    this.loop(num)
                }, this.renderTimeout)
            },

            // ГЛАВНАЯ ФУНКЦИЯ
            start () {

                if (this.bgImage && this.dropImage && this.flood1Image && this.flood2Image) {
                    this.started = true
                    this.loopNumber++

                    this.indentsList = []
                    this.pauseAudio()
                    if (this.dropOn) {
                        this.loop(this.loopNumber)
                    }
                }
            },

            tap () {
                if (!this.tapperMode) {
                    this.dropOn = false
                    this.tapperMode = true
                    this.indentsList = []
                    this.tapperTimeout = setTimeout(() => {
                        this.tapperEnd()
                    }, this.tapperTimeLimit * 1000)
                    this.tapperTimer()
                }
                this.tapperCounter++
                this.tapperUpdate()
                this.dropOnce()
            },
            tapperTimer () {
                setTimeout(() => {
                    if (!this.tapperMode) return
                    this.tapperTimeExpired++
                    this.tapperUpdate()
                    this.tapperTimer()
                }, 1000)
            },
            tapperUpdate () {
                this.$emit('tapper-update', [this.tapperCounter, this.tapperTimeExpired])
            },
            tapperEnd () {
                if (this.tapperMode) {
                    clearTimeout(this.tapperTimeout)
                    let newDpm = Math.round(this.tapperCounter * 60 / this.tapperTimeExpired)
                    this.$store.dispatch('updateSliderValue', newDpm)
                    this.$store.dispatch('updateAppMode', 'simulation')
                    this.$emit('tapper-end', this.tapperCounter)
                    this.tapperCounter = 0
                    this.tapperTimeExpired = 0
                    this.tapperMode = false
                    this.dropOn = true
                }
            },

            // ОДНОКРАТНОЕ ПАДЕНИЕ КАПЛИ (для тапов) (рекурсивная до тех пор, пока не закончится падение капли + отрисовка всплеска)
            dropOnce () {
                this.clearBg()
                if (this.isSplash) {
                    // отрисовка всплеска и увеличивающейся капли
                    this.splashFramesCount += 1
                    this.drawSplash()
                    this.drawDrop(this.dropTopY, this.splashFramesCount / this.splashFramesMax)

                    // когда время всплеска заканчивается
                    if (this.splashFramesCount == this.splashFramesMax) {
                        this.splashFramesCount = 0
                        this.isSplash = false
                        this.clearBg()
                        this.drawDrop(this.dropTopY)
                        return
                    }
                }
                else {
                    // отрисовка падения капли
                    if (!this.indentsList[0]) {this.indentsList.push(this.dropTopY)}
                    this.indentsList[0] += this.dropStep
                    this.drawDrop(this.indentsList[0])
                    if (this.indentsList[0] + this.dropStep >= this.dropBottomY) {
                        this.clearBg()
                        this.dropSoundPlay()
                        this.indentsList = [this.dropTopY]
                        this.isSplash = true
                    }
                }
                setTimeout(() => {
                    this.dropOnce()
                }, this.renderTimeout)
            },

            // УЗНАЕМ КОЭФ. ДЛЯ РАЗМЕРОВ
            calculateScale () {
                // смотрим отношения соотношений сторон заданного размера и сторон дефолтного фона капельницы
                if (this.bgWidth / this.bgHeight > this.defaultBgSize[0] / this.defaultBgSize[1]) {
                    this.scaleK = this.defaultBgSize[1] / this.bgHeight
                    this.bgImage.height = this.bgHeight
                    this.bgImage.width = this.scaled(this.defaultBgSize[0])
                } else {
                    this.scaleK = this.defaultBgSize[0] / this.bgWidth
                    this.bgImage.width = this.bgWidth
                    this.bgImage.height = this.scaled(this.defaultBgSize[1])
                }

                this.clearBg()
                this.resizeAll()
            },

            // МЕНЯЕТ ВСЕ РАЗМЕРЫ И ОТСТУПЫ В СООТВЕТСТВИИ С КОЭФФИЦИЕНТОМ
            resizeAll () {
                this.dropImage.width = this.scaled(this.defaultDropSize[0])
                this.dropImage.height = this.scaled(this.defaultDropSize[1])

                this.splashImage.width = this.scaled(this.defaultSplashSize[0])
                this.splashImage.height = this.scaled(this.defaultSplashSize[1])

                this.flood1Image.width = this.scaled(this.defaultFlood1Size[0])
                this.flood1Image.height = this.scaled(this.defaultFlood1Size[1])

                this.flood2Image.width = this.scaled(this.defaultFlood2Size[0])
                this.flood2Image.height = this.scaled(this.defaultFlood2Size[1])

                this.dropTopY = this.scaled(this.defaultDropTopY)
                this.dropBottomY = this.scaled(this.defaultDropBottomY)
                this.splashY = this.scaled(this.defaultSplashY)

                this.clearBg()

                this.dropOn = true
                this.start()
            },
            scaled (v) {
                return Math.round(v / this.scaleK)
            },
            pauseAudio () {
                this.dropSound.pause()
                this.dropSound.currentTime = 0
                this.floodSound.pause()
                this.floodSound.currentTime = 0
            },
            dropSoundPlay () {
                if (this.getDropSound) {
                    if (this.dropSound.paused) {
                        this.pauseAudio()
                        let promise = this.dropSound.play()
                        if (promise !== null) {
                            promise.catch(() => this.dropSound.play())
                        }
                    }
                }
            },
            floodSoundPlay () {
                if (this.getDropSound) {
                    if (this.floodSound.paused) {
                        this.pauseAudio()
                        let promise = this.floodSound.play()
                        if (promise !== null) {
                            promise.catch(() => this.floodSound.play())
                        }
                    }
                }
            },

            // БАЗОВАЯ ИНИЦИАЛИЗАЦИЯ ВСЕХ ИЗОБРАЖЕНИЙ
            initializeImages () {
                document.getElementById('hiddenImageBg').src = this.bgImageUrl
                document.getElementById('hiddenImageBg').onload = () => {
                    this.bgImage = document.getElementById('hiddenImageBg')
                    this.defaultBgSize[0] = this.bgImage.width
                    this.defaultBgSize[1] = this.bgImage.height
                    this.imageLoaded()
                }
                document.getElementById('hiddenImageDrop').src = this.dropImageUrl
                document.getElementById('hiddenImageDrop').onload = () => {
                    this.dropImage = document.getElementById('hiddenImageDrop')
                    this.defaultDropSize[0] = this.dropImage.width
                    this.defaultDropSize[1] = this.dropImage.height
                    this.imageLoaded()
                }
                document.getElementById('hiddenImageSplash').src = this.splashImageUrl
                document.getElementById('hiddenImageSplash').onload = () => {
                    this.splashImage = document.getElementById('hiddenImageSplash')
                    this.defaultSplashSize[0] = this.splashImage.width
                    this.defaultSplashSize[1] = this.splashImage.height
                    this.imageLoaded()
                }
                document.getElementById('hiddenImageFlood1').src = this.flood1ImageUrl
                document.getElementById('hiddenImageFlood1').onload = () => {
                    this.flood1Image = document.getElementById('hiddenImageFlood1')
                    this.defaultFlood1Size[0] = this.flood1Image.width
                    this.defaultFlood1Size[1] = this.flood1Image.height
                    this.imageLoaded()
                }
                document.getElementById('hiddenImageFlood2').src = this.flood2ImageUrl
                document.getElementById('hiddenImageFlood2').onload = () => {
                    this.flood2Image = document.getElementById('hiddenImageFlood2')
                    this.defaultFlood2Size[0] = this.flood2Image.width
                    this.defaultFlood2Size[1] = this.flood2Image.height
                    this.imageLoaded()
                }
            },
            imageLoaded() {
                this.loadedImgsCounter++
                if (this.loadedImgsCounter == document.images.length) {
                    this.calculateScale()
                }
            }
        },
        mounted() {
            var c = document.getElementById("myCanvas");
            var ctx = c.getContext("2d");
            this.canvas = ctx;
            this.indentsList.push(this.dropTopY)

            this.initializeImages()

            this.floodSound.volume = 0.3
        },

        computed: {
            ...mapGetters(['getDocumentVisibility', 'getDropsPerMin', 'getInfusionSpeedBasedOnWeight', 'getDropSound', 'getAppMode', 'getSliderValue', 'getDropperMode']),
            dropInterval: function () {
                return Math.round(60000 / this.getDropsPerMin)
            },
            dropStep: function () {
                return Math.round(this.renderTimeout * this.pps / 1000)
            },
            dropperMode: {
                get: function () {
                    return this.getDropperMode
                },
                set: function (value) {
                    this.$store.dispatch('updateDropperMode', value)
                }

            },
            isDropOnMode: {
                get: function () {
                    return this.dropperMode === 'drop_on'
                },
                set: function (value) {
                    this.dropperMode = value ? 'drop_on' : 'tapper_prepare_mode'
                }
            },
            isTapperMode: {
                get: function () {
                    return this.dropperMode === 'tapper_mode'
                },
                set: function (value) {
                    this.dropperMode = value ? 'tapper_mode' : 'drop_on'
                }
            },
            isTapperPrepareMode: {
                get: function () {
                    return this.dropperMode === 'tapper_prepare_mode'
                },
                set: function (value) {
                    this.dropperMode = value ? 'tapper_prepare_mode' : 'drop_on'
                }
            },
        },
        watch: {
            isDropOnMode (value) {
                if (value) {
                    this.dropOn = true
                    this.start()
                }
            },
            getAppMode () {
                if (this.tapperMode) {
                    this.tapperEnd()
                }
                this.dropOn = true
                this.start()
            },
            getDropSound () {
                this.pauseAudio()
            },
            bgWidth () {
                this.clearBg()
                this.start()
            },
            bgHeight () {
                this.clearBg()
                this.start()
            },
            dropOn () {
                this.start()
            }
        },
        beforeDestroy() {
            this.dropOn = false
        }
    }
</script>

<style scoped>
    .abs-right {
        position: absolute;
        top: 0;
        left: 50vw;
        font-size: 20px;
    }
    .tap-counter {
        width: 500px;
        height: 200px;
        background-color: aquamarine;
    }
</style>